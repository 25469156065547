import React from 'react'
import classNames from 'classnames'
import { prepareHtml } from '../../../utils/text-to-html-formatter'
import { getCurrentLang } from '../../../utils/current-language-service'
import { getConfigByLanguage } from '../../../utils/common-utils'
import ShadowedContainer from '../../../components/ShadowedContainer/ShadowedContainer'
import componentStyles from './ForecastingComparing.module.scss'

import ComparingEn from '../../../assets/images/en/en_Forecasting-img01.svg'
import ComparingRu from '../../../assets/images/ru/ru_Forecasting-img01.svg'

const images = {
  en: {
    Comparing: ComparingEn
  },
  ru: {
    Comparing: ComparingRu
  }
}

const ForecastingComparing = () => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const { google, inventale, text } = config.forecasting.comparing
  const { Comparing } = images[language]
  const textHtml = prepareHtml(text)
  const orangeTitleClasses = classNames(componentStyles.title, componentStyles.titleOrange)
  const greyTitleClasses = classNames(componentStyles.title, componentStyles.titleGrey)

  return (
    <div className={componentStyles.comparing}>
      <ShadowedContainer className={componentStyles.shadowed}>
        <Comparing/>
      </ShadowedContainer>
      <div className={componentStyles.content}>
        <h4 className={orangeTitleClasses}>{inventale}</h4>
        <h4 className={greyTitleClasses}>{google}</h4>
        <p className={componentStyles.text} dangerouslySetInnerHTML={{ __html: textHtml }}/>
      </div>
    </div>
  )
}

export default ForecastingComparing
