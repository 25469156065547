import React from 'react'
import { getCurrentLangKey } from 'ptz-i18n'
import { getConfigByLanguage } from '../../../utils/common-utils'
import Layout from '../../../components/Layout/Layout'
import IndexContact from '../../index/IndexContact/IndexContact'
import ProductNavigation from '../../../components/ProductNavigation/ProductNavigation'
import ForecastingAdvantages from '../ForecastingAdvantages/ForecastingAdvantages'
import ForecastingTotal from '../../common/CommonTotal/CommonTotal'
import ForecastingTitle from '../../common/CommonPageTitle/CommonPageTitle'
import ForecastingRevenue from '../../common/CommonCheckedList/CommonCheckedList'
import ForecastingCases from '../../common/CommonCases/CommonCases'
import ForecastingComparing from '../ForecastingComparing/ForecastingComparing'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './ForecastingPage.module.scss'

const ForecastingPage = ({ language, location }) => {
  const config = getConfigByLanguage(language)
  const { cases, revenue, seo, title, total } = config.forecasting
  const productNavigationClasses = {
    productNavigationClass: componentStyles.productNavigation,
  }
  const listItemClass = componentStyles.listItem
  const additionValueClass = componentStyles.additionValue
  const additionDescriptionValueClass = componentStyles.additionDescriptionValue

  return (
    <Layout language={language} location={location} seo={seo}>
      <div className={componentStyles.forecasting}>
        <div className={layoutStyles.container}>
          <ProductNavigation customClasses={productNavigationClasses} />
          <ForecastingTitle
            annotation={title.annotation}
            description={title.description}
            title={title.title}
          />
          <ForecastingAdvantages
            customClasses={{
              additionDescriptionValueClass,
              additionValueClass,
              listItemClass,
            }}
          />
          <ForecastingRevenue
            annotation={revenue.annotation}
            list={revenue.list}
            title={revenue.title}
          />
          <ForecastingCases
            customClasses={{
              listItemClass,
            }}
            list={cases.list}
            title={cases.title}
          />
          <ForecastingComparing />
        </div>
        <ForecastingTotal
          customClasses={{
            listItemClass,
          }}
          dashed={true}
          list={total.list}
          title={total.title}
        />
      </div>
    </Layout>
  )
}

export default ForecastingPage
