import React from 'react'
import classNames from 'classnames'
import isString from 'lodash-es/isString'
import { prepareHtml } from '../../../utils/text-to-html-formatter'
import { getCurrentLang } from '../../../utils/current-language-service'
import { getConfigByLanguage } from '../../../utils/common-utils'
import ValueLineDescription from '../../../components/ValueLineDescription/ValueLineDescription'
import DashedList from '../../../components/DashedList/DashedList'
import componentStyles from './ForecastingAdvantages.module.scss'

const ForecastingAdvantages = ({ customClasses }) => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const { advantages, advantagesControl } = config.forecasting
  const listItemClasses = customClasses && customClasses.listItemClass
  const additionValueClass = classNames(customClasses && customClasses.additionValueClass, componentStyles.controlValue)
  const additionDescriptionValueClass = customClasses && customClasses.additionDescriptionValueClass
  const advantagesAdditionValueClass = classNames(additionValueClass, componentStyles.controlNumber)

  const advantagesList = advantages.map(({ addition, text, title }, index) => {
    const itemClass = componentStyles.listItem
    let preparedText
    if (isString(text)) {
      const html = prepareHtml(text)
      preparedText = (
        <div className={componentStyles.listItemText}>
          <p>{html}</p>
        </div>
      )
    } else {
      preparedText = (
        <div className={componentStyles.listItemText}>
          <p>{text.paragraph}</p>
          <DashedList customClasses={{
            listItemClass: listItemClasses,
          }} list={text.list}/>
        </div>
      )
    }
    let additionElement = null
    if (addition) {
      additionElement = (
        <div className={componentStyles.listItemAddition}>
          <ValueLineDescription customClasses={{
            descriptionValueClass: additionDescriptionValueClass,
            valueClass: additionValueClass,
          }} value={addition.value} description={addition.description}/>
        </div>
      )
    }
    const titleElement = title ? (<h4 className={componentStyles.listItemTitle}>{title}</h4>) : null

    return (
      <li className={itemClass} key={`item-${index}`}>
        {titleElement}
        <div className={componentStyles.listItemContent}>
          {preparedText}
          {additionElement}
        </div>
      </li>
    )
  })

  return (
    <div className={componentStyles.advantages}>
      <ul className={componentStyles.list}>
        {advantagesList}
      </ul>
      <div className={componentStyles.control}>
        <p className={componentStyles.controlText}
           dangerouslySetInnerHTML={{ __html: prepareHtml(advantagesControl.text) }}/>
        <ValueLineDescription customClasses={{
            descriptionValueClass: additionDescriptionValueClass,
            valueClass: advantagesAdditionValueClass,
          }} blockAlignment={'left'} value={advantagesControl.addition.value} description={advantagesControl.addition.description} />
      </div>
    </div>
  )
}

export default ForecastingAdvantages
