import React from 'react'
import classNames from 'classnames'
import componentStyles from './CommonCheckedList.module.scss'
import Annotation from '../../../components/Annotation/Annotation'
import CheckedText from '../../../components/CheckedText/CheckedText'
import isNil from 'lodash-es/isNil'
import { prepareHtml } from '../../../utils/text-to-html-formatter'

class CommonCheckedList extends React.Component {
  render() {
    const { annotation, customClasses, list, title } = this.props
    const containerClasses = classNames(componentStyles.container, customClasses && customClasses.containerClass)
    const contentClasses = classNames(componentStyles.content, customClasses && customClasses.contentClass)
    const listClasses = classNames(componentStyles.list, {
      [componentStyles.listWithoutAnnotation]: isNil(annotation)
    }, customClasses && customClasses.listClass)
    const listItemClasses = classNames(componentStyles.listItem, customClasses && customClasses.listItemClass)
    const listElements = list.map(({text, title},index) => (
      <CheckedText customClasses={{
        containerClass: listItemClasses
      }} component={'li'} text={text} title={title} key={`item-${index}`}/>
    ))
    const preparedTitle = prepareHtml(title)
    const annotationElement = annotation ? (<Annotation className={componentStyles.annotation} text={annotation} />) : null

    return(
      <div className={containerClasses}>
        <h2 className={componentStyles.title} dangerouslySetInnerHTML={{__html: preparedTitle}}/>
        <div className={contentClasses}>
          <ul className={listClasses}>
            {listElements}
          </ul>
          {annotationElement}
        </div>
      </div>
    )
  }
}

export default CommonCheckedList
